import Vue from 'vue';

import Footer from '@/components/Shared/Footer/Footer';
import ClientNav from '@/components/Clients/ClientNav';
import '../About/About.scss';
import './Portfolio.scss';

const Story = Vue.extend({
  render() {
    return (
      <div class="portfolio">
        <ClientNav />

        <div class="about">

          <img src={require('@/assets/images/section-1.png')} class="lazyload about__image" alt="Our story" />

          <div>
            <div class="about__content">
              <h2 class="about__title">Portfolio</h2>
              <p class="about__description">Okay, here you can write a whole history of your brand or make a post about recent collection, collaboration or event. The most simple and elegant way to make people interested in what you do – tell them a good story. Ofcorse good story needs a good design, but you don’t need to worry – we’ve already dealed with it. Just try to remember all the fun, add a few photos or sliders and here it is!</p>
            </div>
          </div>
        </div>

        <div class="about">

          <img src={require('@/assets/images/section-1.png')} class="lazyload about__image" alt="Our story" />

          <div>
            <div class="about__content">
              <h2 class="about__title">Portfolio</h2>
              <p class="about__description">Okay, here you can write a whole history of your brand or make a post about recent collection, collaboration or event. The most simple and elegant way to make people interested in what you do – tell them a good story. Ofcorse good story needs a good design, but you don’t need to worry – we’ve already dealed with it. Just try to remember all the fun, add a few photos or sliders and here it is!</p>
            </div>
          </div>
        </div>

        <div class="about">

          <img src={require('@/assets/images/section-1.png')} class="lazyload about__image" alt="Our story" />

          <div>
            <div class="about__content">
              <h2 class="about__title">Portfolio</h2>
              <p class="about__description">Okay, here you can write a whole history of your brand or make a post about recent collection, collaboration or event. The most simple and elegant way to make people interested in what you do – tell them a good story. Ofcorse good story needs a good design, but you don’t need to worry – we’ve already dealed with it. Just try to remember all the fun, add a few photos or sliders and here it is!</p>
            </div>
          </div>
        </div>

        <div class="about">

          <img src={require('@/assets/images/section-1.png')} class="lazyload about__image" alt="Our story" />

          <div>
            <div class="about__content">
              <h2 class="about__title">Portfolio</h2>
              <p class="about__description">Okay, here you can write a whole history of your brand or make a post about recent collection, collaboration or event. The most simple and elegant way to make people interested in what you do – tell them a good story. Ofcorse good story needs a good design, but you don’t need to worry – we’ve already dealed with it. Just try to remember all the fun, add a few photos or sliders and here it is!</p>
            </div>
          </div>
        </div>

        <div class="about">

          <img src={require('@/assets/images/section-1.png')} class="lazyload about__image" alt="Our story" />

          <div>
            <div class="about__content">
              <h2 class="about__title">Portfolio</h2>
              <p class="about__description">Okay, here you can write a whole history of your brand or make a post about recent collection, collaboration or event. The most simple and elegant way to make people interested in what you do – tell them a good story. Ofcorse good story needs a good design, but you don’t need to worry – we’ve already dealed with it. Just try to remember all the fun, add a few photos or sliders and here it is!</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    );
  },
});

export default Story;
